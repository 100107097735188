import vanillaScrollspy from '../../../../Salvagnini.Scrollspy/Resources/Private/Scripts/scrollspy/vanillajs-scrollspy';

document.addEventListener('DOMContentLoaded', function(event) {
    document.querySelectorAll('.adam__history').forEach((el) => {
        const nav = el.querySelector('.adam__history__navigation');
        const scrollspy = vanillaScrollspy(nav, 2000, 'easeOutSine', window.innerWidth <= 768 ? window.innerHeight / 3 : window.innerHeight / 2);
        scrollspy.init();

        const first = nav.querySelector('.adam__history__navigation__item:first-child a');
        first.classList.add('item--active');
        el.querySelector('.adam__history__year span').innerHTML = first.innerHTML;

        el.querySelectorAll('.adam__history__item').forEach((el) => {
            const contents = el.querySelectorAll('img');
            if (contents.length > 1) { contents[0].setAttribute("src", contents[1].src); }
        });

        //Events
        document.addEventListener('scroll', (e) => {
            window.setTimeout(() => { // necessary for correct year update
                const item = el.querySelector('.adam__history__navigation a.item--active');
                if (item) { el.querySelector('.adam__history__year span').innerHTML = item.innerHTML; }
            }, 1000 / 60);
        });

        window.addEventListener('resize', (e) => {
            scrollspy.offsetTop = window.innerWidth <= 768 ? window.innerHeight / 3 : window.innerHeight / 2;
        }, true);
    });
});
